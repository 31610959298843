import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { connect } from "react-redux";
import ReactSelectNonCreate from "react-select";
import "react-table-6/react-table.css";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import NumberFormat from "react-number-format";
import { getClientes } from "../../actions/clientes";
import { getSaldosClientes } from "../../actions/cobranzas";
import { getVendedores } from "../../actions/vendedores";
import { getZonas } from "../../actions/zonas";
import AlertContext from "../../context/alert/alertContext";
import { esX } from "../../utils/functions";
import subscribeTo from "../../utils/subscribeTo";
import CobranzaForm from "./CobranzaForm";
import CuentaCorriente from "./CuentaCorriente";

import Button from "@material-ui/core/Button";

import Menu from "@material-ui/core/Menu";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { canales } from "../../config/constantes";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(),
  },
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  gridContainer: {
    width: "96vw",
    overflowX: "auto",
  },
  gridContainerLoading: {
    cursor: "progress!important",
    opacity: "0.5",

    "-webkit-filter": "blur(2px)",
    filter: "blur(2px)",
  },
  listIcon: {
    //paddingRight: '25px'
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
  },
  tableCell: {
    padding: "11px",
  },

  deleteButton: {
    cursor: "pointer",
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginLeft: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,0,0,0.1)",
    },
  },
  editButton: {
    cursor: "pointer",
    color: "blue",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  moneyButton: {
    cursor: "pointer",
    color: "green",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  moneyButtonR: {
    cursor: "pointer",
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  facturarButton: {
    cursor: "pointer",
    color: "#CCCC00",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,204,0,0.1)",
    },
  },

  approveButton: {
    cursor: "pointer",
    color: "green",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },

  disapproveButton: {
    cursor: "pointer",
    color: "grey",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "50%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const Cobranzas = ({
  dispatch,
  loading,
  cobranzas,
  vendedores,
  clientes,
  loading_clientes,
  loading_grupos,
  filtered,
  usuarios,
  grupos,
  ventas_para_cobrar,
  loading_zonas,
  zonas,
  saldos_clientes,
}) => {
  const confirm = useConfirm();

  const [filterOpen, setFilterOpen] = useState(false);
  const [dropdownClientes, setDropdownClientes] = useState([]);
  const [ultimaRendicion, setUltimaRendicion] = useState(null);
  const alertContext = useContext(AlertContext);
  const [openCliente, setOpenCliente] = useState(null);
  const [canalesParaExportar, setCanalesParaExportar] = useState("0");
  const [resumenesFiltradosPorCanal, setResumenesFiltradosPorCanal] = useState(
    {}
  );

  const [filters, setFilters] = useState({
    cliente: null,
    canales: [],
    vendedor: null,
  });

  const [ruta, setRuta] = useState(null);

  useEffect(() => {
    dispatch(getSaldosClientes());
    dispatch(getClientes());
    dispatch(getZonas());
    dispatch(getVendedores());
    if (!loading) {
      subscribeTo("cobranzasChanged", (err, data) => {
        dispatch(getSaldosClientes());
      });
      return () => {
        subscribeTo("cobranzasChanged", null);
      };
    }
  }, [dispatch, loading]);

  useEffect(() => {
    if (!loading_clientes && !loading_grupos) {
      setDropdownClientes(
        clientes
          .filter((c) => !c.grupo || c.grupo.cuenta_corriente_propia)
          //.concat(clientes.filter((c) => c.grupo && !c.grupo.cuenta_corriente_propia))
          .map((cliente) => ({
            value: cliente.id,
            label: cliente.nombre,
          }))
          .concat(
            grupos
              .filter((g) => !g.cuenta_corriente_propia)
              .map((g) => ({
                value: "g" + g.id,
                label: g.nombre,
              }))
          )
          .sort((a, b) => a.label > b.label)
      );
    }
  }, [loading_clientes, loading_grupos]);

  const classes = useStyles();

  const mobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  let openFilter = false;

  const [orden, setOrden] = useState("asc");
  const [ordenPor, setOrdenPor] = useState("");

  function comparadorDescendiente(a, b, ordenPor) {
    if (b[ordenPor] < a[ordenPor]) {
      return -1;
    }
    if (b[ordenPor] > a[ordenPor]) {
      return 1;
    }
    return 0;
  }

  function ordenamientoEstablecido(array, comparator) {
    const ordenEstablecido = array.map((el, index) => [el, index]);

    ordenEstablecido.sort((a, b) => {
      const orden = comparator(a[0], b[0]);
      if (orden !== 0) return orden;
      return a[1] - b[1];
    });

    return ordenEstablecido.map((el) => el[0]);
  }

  function getComparator(orden, ordenPor) {
    return orden === "desc"
      ? (a, b) => comparadorDescendiente(a, b, ordenPor)
      : (a, b) => -comparadorDescendiente(a, b, ordenPor);
  }

  const manejoDeOrdenamientoRequerido = (event, property) => {
    const isAsc = ordenPor === property && orden === "asc";
    setOrden(isAsc ? "desc" : "asc");
    setOrdenPor(property);
  };

  const celdasPrincipales = [
    { id: "id", label: "Cod" },
    { id: "canal_id", label: "Canal" },
    { id: "nombre", label: "Cliente" },
    { id: "dias_primera_deuda", label: "Dias" },
    { id: "balance_a", label: "Saldo" },
    { id: "balance_vencido", label: "Saldo Vencido" },
    { id: "balance_x", label: "Saldo X" },
    { id: "none", label: " " },
  ];

  const celdasPrincipalesResumenes = [
    { id: "contador", label: "Cantidad de Clientes" },
    { id: "canal", label: "Canal" },
    { id: "balance_a", label: "Saldo" },
    { id: "balance_vencido", label: "Total Vencido A" },
    { id: "balance_x", label: "Saldo X" },
    { id: "balance_vencido_total", label: "Total Vencido A+X" },
    { id: "balance_total", label: "Total" },
  ];

  function agruparClientes() {
    let nombreGrupo =
      typeof filters.cliente === "string" &&
      filters.cliente.indexOf("g") !== -1 &&
      grupos?.filter((grupo) => grupo.id === filters.cliente);

    let agrupados = {
      id: 0,
      canal_id: "",
      nombre: "",
      dias_primera_deuda: 0,
      plazo_pago: 0,
      balance_a: 0,
      balance_vencido: 0,
      balance_vencido_total: 0,
      balance_x: 0,
    };
    if (nombreGrupo) {
      for (const saldo of saldos_clientes) {
        if (saldo.grupo_id === filters.cliente) {
          agrupados = {
            id: nombreGrupo[0].id,
            canal_id: saldo.canal_id,
            nombre: nombreGrupo[0].nombre,
            dias_primera_deuda: (agrupados.dias_primera_deuda +=
              saldo.dias_primera_deuda),
            plazo_pago: nombreGrupo[0].plazo_pago,
            balance_a: (agrupados.balance_a += saldo.balance_a),
            balance_vencido: (agrupados.balance_vencido +=
              saldo.balance_vencido),

            balance_x: (agrupados.balance_x += saldo.balance_x),
            balance_vencido_total: (agrupados.balance_vencido_total +=
              saldo.balance_x + saldo.balance_vencido),
          };
        }
      }
    }

    return [agrupados];
  }

  function filtrosElegidos(clientes) {
    let grupoelegido =
      typeof filters.cliente === "string" &&
      filters.cliente.indexOf("g") !== -1 &&
      grupos.find((gr) => gr.id === filters.cliente);

    if (grupoelegido) {
      let agrupados = agruparClientes();
      let finalOrder = ordenamientoEstablecido(
        agrupados,
        getComparator(orden, ordenPor)
      );

      return finalOrder;
    }

    let filtered = clientes
      .filter((sc) => {
        if (
          filters.cliente ||
          filters.canales.length ||
          filters.vendedor
        ) {
          if (filters.cliente) {
            if (
              typeof filters.cliente !== "string" ||
              filters.cliente.indexOf("g") === -1
            ) {
              return sc.id === filters.cliente;
            } else {
              return sc.grupo_id == filters.cliente.substring(1);
            }
          } else if (filters.canales.length) {
            return filters.canales.indexOf(sc.canal_id) !== -1;
          } else if (filters.vendedor) {
            return sc.vendedor_id === filters.vendedor;
          }
        } else return true;
      })
      .sort((a, b) => {
        if (a.canal_id < b.canal_id) return -1;
        if (a.canal_id > b.canal_id) return 1;
        if (a.balance_a + a.balance_x > b.balance_a + b.balance_x) return -1;
        if (a.balance_a + a.balance_x < b.balance_a + b.balance_x) return 1;

        return 0;
      });
    let finalOrder = ordenamientoEstablecido(
      filtered,
      getComparator(orden, ordenPor)
    );
    if (!canalesParaExportar.length) {
      setCanalesParaExportar("0");
    }
    return finalOrder;
  }

  const seleccionFiltrosPorCanal = (saldosClientes, canales) => {
    let normalizaciondeCanales = {};

    for (const cliente of saldosClientes) {
      if (canales.indexOf(cliente.canal_id) === -1) {
        continue;
      }
      if (!normalizaciondeCanales[cliente.canal_id]) {
        normalizaciondeCanales[cliente.canal_id] = {
          canal: cliente.canal_id,
          balance_a: 0,
          balance_vencido: 0,
          balance_x: 0,
          balance_total: 0,
          balance_vencido_total: 0,
          contador: 0,
        };
      }

      normalizaciondeCanales[cliente.canal_id].balance_total =
        normalizaciondeCanales[cliente.canal_id].balance_a +
        normalizaciondeCanales[cliente.canal_id].balance_x;
      normalizaciondeCanales[cliente.canal_id].balance_a += cliente.balance_a;
      normalizaciondeCanales[cliente.canal_id].balance_vencido +=
        cliente.balance_vencido;
      normalizaciondeCanales[cliente.canal_id].balance_x += cliente.balance_x;
      normalizaciondeCanales[cliente.canal_id].balance_vencido_total +=
        cliente.balance_vencido + cliente.balance_x;
      normalizaciondeCanales[cliente.canal_id].contador++;
    }
    setResumenesFiltradosPorCanal(normalizaciondeCanales);
    setCanalesParaExportar(canales.join("").toString());
  };

  return (
    <Fragment>
      <div>
        <CobranzaForm
          ultimaRendicion={ultimaRendicion}
          setUltimaRendicion={(id) => {
            setUltimaRendicion(id);
          }}
        />
        <CuentaCorriente
          current={openCliente}
          setCurrent={setOpenCliente}
          isGroup={
            filters.cliente &&
            typeof filters.cliente === "string" &&
            filters.cliente.indexOf("g") === -1
          }
        />
      </div>

      <div>
        <div className={classes.root}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<Icon>expand_more</Icon>}
              id="panel1a-header"
              aria-controls="panel1a-content"
              className={classes.summary}
            >
              <Typography className={classes.heading}>
                Saldos de Clientes
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelFilter}>
              {clientes &&
                clientes.length > 0 &&
                canales &&
                canales.length > 0 && (
                  <>
                    <Grid container className={classes.root} spacing={2} md={8}>
                      <Grid item xs={12} md={4}>
                        <FormControl
                          variant="outlined"
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingTop: "8px",
                          }}
                        >
                          <ReactSelectNonCreate
                            fullWidth
                            isClearable={true}
                            value={
                              filters.cliente &&
                              dropdownClientes.find(
                                (p) => p.value === filters.cliente
                              )
                                ? {
                                    label: dropdownClientes.find(
                                      (p) => p.value === filters.cliente
                                    ).label,
                                    value: filters.cliente,
                                  }
                                : ""
                            }
                            name="cliente"
                            placeholder="Seleccionar Cliente/Grupo... *"
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                                marginTop: "-8px",
                              }),
                            }}
                            menuPortalTarget={document.body}
                            onChange={(e) => {
                              return e && e.value
                                ? setFilters({ ...filters, cliente: e.value })
                                : setFilters({ ...filters, cliente: null });
                            }}
                            options={dropdownClientes}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormControl
                          variant="outlined"
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingTop: "8px",
                          }}
                        >
                          <InputLabel htmlFor="select-tipo-comprobante">
                            Canal
                          </InputLabel>
                          <Select
                            input={
                              <OutlinedInput
                                style={{
                                  height: "40px",
                                  width: "80%",
                                }}
                                id="select-tipo-comprobante"
                              />
                            }
                            value={filters.canales}
                            name="canales"
                            multiple={true}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                canales: e.target.value,
                              });
                              seleccionFiltrosPorCanal(
                                saldos_clientes,
                                e.target.value
                              );
                            }}
                          >
                            {canales.map((canal) => (
                              <MenuItem value={canal.id} key={canal.id}>
                                {canal.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} md={4}>
                        {vendedores && vendedores.length > 0 && (
                          <FormControl
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "100%",
                              paddingTop: "8px",
                            }}
                          >
                            <InputLabel htmlFor="select-tipo-comprobante">
                              Vendedor
                            </InputLabel>
                            <Select
                              input={
                                <OutlinedInput
                                  style={{
                                    height: "40px",
                                    width: "80%",
                                  }}
                                  id="select-tipo-comprobante"
                                />
                              }
                              value={filters.vendedores}
                              name="vendedor"
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  vendedor: e.target.value,
                                });
                              }}
                            >
                              {vendedores.map((vendedor) => (
                                <MenuItem value={vendedor.id} key={vendedor.id}>
                                  {vendedor.nombre}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      md={9}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid justifyContent="flex-start">
                        <span>Exportar por: </span>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            window.open(
                              `/api/cobranzas/exportar_saldos/${canalesParaExportar}/nombre` +
                                (filters.cliente ? `/${filters.cliente}` : "")
                            );
                          }}
                        >
                          Nombre
                        </Button>{" "}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            window.open(
                              `/api/cobranzas/exportar_saldos/${canalesParaExportar}/cuit` +
                                (filters.cliente ? `/${filters.cliente}` : "")
                            );
                          }}
                        >
                          Razón Social
                        </Button>{" "}
                      </Grid>
                    </Grid>
                  </>
                )}
            </AccordionDetails>
          </Accordion>
          {Object.keys(resumenesFiltradosPorCanal).length > 0 && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}
                id="panel1a-header"
                aria-controls="panel1a-content"
                className={classes.summary}
              >
                <Typography className={classes.heading}>
                  Resumenes de Canales
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.expansionPanel}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeader}>
                        {celdasPrincipalesResumenes.map((celdaPrincipal) => (
                          <TableCell key={celdaPrincipal.id} align="center">
                            {celdaPrincipal.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(resumenesFiltradosPorCanal).map((canal) => (
                        <TableRow className={classes.tableRow}>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            {resumenesFiltradosPorCanal[canal].contador}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            {
                              canales.find(
                                (c) =>
                                  c.id ===
                                  resumenesFiltradosPorCanal[canal].canal
                              ).nombre
                            }
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              label="balance_a"
                              value={
                                resumenesFiltradosPorCanal[canal].balance_a
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              label="balance_vencido"
                              value={
                                resumenesFiltradosPorCanal[canal]
                                  .balance_vencido
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              label="balance_a"
                              value={
                                resumenesFiltradosPorCanal[canal].balance_x
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              label="balance_a"
                              value={
                                resumenesFiltradosPorCanal[canal]
                                  .balance_vencido_total
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>{" "}
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              label="balance_a"
                              value={
                                resumenesFiltradosPorCanal[canal].balance_total
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )}

          {filters.cliente &&
            typeof filters.cliente === "string" &&
            filters.cliente.indexOf("g") !== -1 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  id="panel1a-header"
                  aria-controls="panel1a-content"
                  className={classes.summary}
                >
                  <Typography className={classes.heading}>
                    Resumen de grupo:{" "}
                    {
                      grupos?.filter(
                        (grupo) => grupo.id == filters.cliente.slice(1)
                      )[0].nombre
                    }
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanel}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className={classes.tableHeader}>
                          {celdasPrincipalesResumenes
                            .slice(2, 5)
                            .map((celdaPrincipal) => (
                              <TableCell key={celdaPrincipal.id} align="center">
                                {celdaPrincipal.label}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow className={classes.tableRow}>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                            onClick={(e) => {
                              setOpenCliente({ id: filters.cliente, x: false });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              label="balance_a"
                              value={filtrosElegidos(saldos_clientes).reduce(
                                (acc, cli) => acc + cli.balance_a,
                                0
                              )}
                              variant="outlined"
                              fullWidth
                            />{" "}
                            <Icon>launch</Icon>
                          </TableCell>

                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              label="balance_vencido"
                              value={filtrosElegidos(saldos_clientes).reduce(
                                (acc, cli) => acc + cli.balance_vencido,
                                0
                              )}
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            width="3%"
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              label="balance_x"
                              value={filtrosElegidos(saldos_clientes).reduce(
                                (acc, cli) => acc + cli.balance_x,
                                0
                              )}
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )}

          <div className={classes.expansionPanel}>
            {saldos_clientes && saldos_clientes.length > 0 ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow width="3%" className={classes.tableHeader}>
                      {celdasPrincipales.map((celdaPrincipal) => (
                        <TableCell
                          key={celdaPrincipal.id}
                          align="center"
                          sortDirection={
                            ordenPor === celdaPrincipal.id ? orden : false
                          }
                        >
                          <TableSortLabel
                            active={ordenPor === celdaPrincipal.id}
                            direction={
                              ordenPor === celdaPrincipal.id ? orden : "asc"
                            }
                            onClick={(e) =>
                              manejoDeOrdenamientoRequerido(
                                e,
                                celdaPrincipal.id
                              )
                            }
                          >
                            {celdaPrincipal.label}
                            {ordenPor === celdaPrincipal.id ? (
                              <span className={classes.visuallyHidden}>
                                {orden === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtrosElegidos(saldos_clientes).map((cliente, index) => (
                      <>
                        <TableRow className={classes.tableRow} key={index}>
                          <TableCell
                            className={classes.tableCell}
                            width="5%"
                            align="center"
                          >
                            {cliente.id}
                          </TableCell>{" "}
                          <TableCell
                            className={classes.tableCell}
                            width="5%"
                            align="center"
                          >
                            {
                              canales.find((c) => c.id === cliente.canal_id)
                                .nombre
                            }
                          </TableCell>
                          <TableCell align="center">{cliente.nombre}</TableCell>
                          <TableCell align="center">
                            {!cliente.plazo_pago
                              ? "Pronto pago (" +
                                cliente.dias_primera_deuda +
                                " días)"
                              : cliente.plazo_pago +
                                " dias (" +
                                (cliente.dias_primera_deuda -
                                  cliente.plazo_pago) +
                                " días)"}
                          </TableCell>
                          <TableCell
                            width="8%"
                            align="right"
                            onClick={(e) => {
                              setOpenCliente({ id: cliente.id, x: false });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={cliente.balance_a}
                              variant="outlined"
                              fullWidth
                            />{" "}
                            <Icon>launch</Icon>
                          </TableCell>{" "}
                          <TableCell
                            width="8%"
                            align="right"
                            onClick={(e) => {
                              setOpenCliente({ id: cliente.id, x: false });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={cliente.balance_vencido}
                              variant="outlined"
                              fullWidth
                            />
                            <Icon>launch</Icon>
                          </TableCell>{" "}
                          <TableCell
                            width="8%"
                            align="right"
                            onClick={(e) => {
                              setOpenCliente({ id: cliente.id, x: true });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={cliente.balance_x}
                              variant="outlined"
                              fullWidth
                            />{" "}
                            <Icon>launch</Icon>
                          </TableCell>
                          <TableCell align="center" width="5%">
                            {" "}
                            {cliente.balance_a !== 0 && (
                              <span
                                title="Comprobante saldo"
                                className={classes.moneyButton}
                                onClick={(e) => {
                                  let w = window.open(
                                    "/api/comprobanteSaldo/pdf/" +
                                      (cliente.id ? cliente.id : "") +
                                      "/1"
                                  );
                                }}
                              >
                                <Icon className={classes.listIcon}>
                                  attach_money
                                </Icon>
                              </span>
                            )}{" "}
                            {cliente.balance_x !== 0 && (
                              <span
                                title="Comprobante saldo X"
                                className={classes.moneyButtonR}
                                onClick={(e) => {
                                  let w = window.open(
                                    "/api/comprobanteSaldo/pdf/" +
                                      (cliente.id ? cliente.id : "") +
                                      "/3"
                                  );
                                }}
                              >
                                <Icon className={classes.listIcon}>
                                  attach_money
                                </Icon>
                              </span>
                            )}
                          </TableCell>
                        </TableRow>

                        {cliente.ventas &&
                          cliente.ventas.map((venta, index) => {
                            return (
                              <TableRow
                                key={index}
                                style={{
                                  backgroundColor: "#ececec",
                                  display: "none",
                                }}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  width="5%"
                                  align="center"
                                >
                                  {venta.id}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="center"
                                >
                                  <Grid container>
                                    <Grid item xs={6} md={6}>
                                      {moment(venta.fecha_venta).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                      {venta.numero_factura
                                        ? venta.numero_factura ===
                                            "A-0000000000" ||
                                          venta.numero_factura ===
                                            "X-0000000000"
                                          ? "Saldo pendiente"
                                          : venta.numero_factura
                                        : `X${"0".repeat(
                                            9 - venta.id.toString().length
                                          )}${venta.id}`}
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  width="8%"
                                  align="center"
                                >
                                  <NumberFormat
                                    displayType="text"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    mask=""
                                    prefix="$"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    margin="dense"
                                    name="horaAtencion2Fin"
                                    label="Fin Tarde"
                                    value={
                                      !esX(venta.tipo_comprobante_id)
                                        ? venta.balance
                                        : ""
                                    }
                                    variant="outlined"
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  width="8%"
                                  align="center"
                                >
                                  <NumberFormat
                                    displayType="text"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    mask=""
                                    prefix="$"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    margin="dense"
                                    name="horaAtencion2Fin"
                                    label="Fin Tarde"
                                    value={
                                      esX(venta.tipo_comprobante_id)
                                        ? venta.balance
                                        : ""
                                    }
                                    variant="outlined"
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell align="center" width="5%">
                                  <span
                                    title="Comprobante"
                                    className={classes.editButton}
                                    onClick={(e) => {
                                      let w = window.open(
                                        "/api/invoice/pdf/original/" + venta.id
                                      );
                                    }}
                                  >
                                    <Icon className={classes.listIcon}>
                                      receipt
                                    </Icon>
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              "Cargando..."
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuarios: state.usuarios.usuarios,
    clientes: state.clientes.clientes,
    loading_clientes: state.clientes.loading,
    cobranzas: state.cobranzas.cobranzas,
    saldos_clientes: state.cobranzas.saldos_clientes,
    filtered: state.cobranzas.filtered,
    loading: state.cobranzas.loading,
    grupos: state.grupos.grupos,
    filters: state.cobranzas.filters,
    ventas_para_cobrar: state.ventas.ventas_para_cobrar,
    loading_zonas: state.zonas.loading,
    zonas: state.zonas.zonas,
    loading_grupos: state.grupos.loading,
    vendedores: state.vendedores.vendedores,
  };
};

export default connect(mapStateToProps)(Cobranzas);
