import { CircularProgress, Snackbar } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import BigNumber from "bignumber.js";
import ObjectID from "bson-objectid";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-date-picker";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import ReactSelectNonCreate from "react-select";
import ReactSelect from "react-select/creatable";
import { getCajas } from "../../actions/cajas";
import { getCheques } from "../../actions/cheques";
import { getChequesPropios } from "../../actions/cheques_propios";
import { addCliente, getClientes } from "../../actions/clientes";
import { getListasPrecios } from "../../actions/listas_precios";
import { getProductos } from "../../actions/productos";
import { getTransportistas } from "../../actions/transportistas";
import {
  addVenta,
  clearCurrentVenta,
  setOpenVenta,
  updateVenta,
} from "../../actions/ventas";
import { getZonas } from "../../actions/zonas";
import {
  cEstados,
  cValorMiPyme,
  idProductoOtros,
  tiposComprobante,
  tiposFormasDePago,
} from "../../config/constantes";
import { cIdProductoOtros } from "../../config/constantesServer";
import AlertContext from "../../context/alert/alertContext";
import {
  calcWorkingDays,
  discriminaIva,
  esNotaDeDebito,
  getNextDayOfTheWeek,
  esNotaDeCredito,
  tieneRgIva,
} from "../../utils/functions";
import { calcularBultos } from "./../../utils/functions";
import AuthContext from "../../context/auth/authContext";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formHeader: {
    backgroundColor: "#4caf50",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
  divider: {
    display: "inline-block",
    width: 0,
    height: "1em",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
  },
  abasto: {
    marginLeft: "8px",
    padding: "8px",
    backgroundColor: "lightyellow",
    border: "black 1px dashed",
  },
}));

const CustomTextField = (props) => {
  return <TextField {...props} inputProps={{ tabIndex: "-1" }} />;
};

const VentaForm = ({
  children,
  current,
  saving,
  open,
  open_punto_logistico,
  all_ventas,
  error,
  zonas,
  bypassIo,
  dispatch,
  clientes,
  loading_clientes,
  cajas,
  loading_cajas,
  cheques,
  loading_cheques,
  cheques_propios,
  loading_cheques_propios,
  productos,
  loading_productos,
  listas_precios,
  loading_transportistas,
  transportistas,
  panel,
  setAlert,
}) => {
  BigNumber.config({ DECIMAL_PLACES: 2 });
  var d = new Date();
  var d2 = new Date();
  const emptyVenta = () => ({
    tipo: null,
    fecha_venta: new Date(),
    fecha_validez: new Date(d2.setMonth(parseInt(d.getMonth()) + 1)),
    fecha_salida: null,
    fecha_recepcion: null,
    fecha_vencimiento: null,
    numero_remito: null,
    numero_factura: null,
    cliente_id: null,
    cuit: null,
    orden: null,
    camioneta: null,
    notas: null,
    observaciones_factura: null,
    mi_pyme: 0,
    remito: null,
    pagos: [],
    items: [],
    emitirFactura: false,
    aprobarFactura: false,
    cae: null,
    estado_id: null,
    bultos: 0,
  });
  const [modificadorBultos, setModificadorBultos] = useState(false);
  const [venta, setVenta] = useState(emptyVenta());
  const [balance, setBalance] = useState(0);
  const [balanceC3, setBalanceC3] = useState(0);

  const [cantidad, setCantidad] = useState(0);
  const [cantidadC3, setCantidadC3] = useState(0);

  const [rgIva, setRgIva] = useState(0);

  const [isPreview, setIsPreview] = useState(current ? true : false);

  const [enviarMailDisabled, setEnviarMailDisabled] = useState(false);
  const [currentMail, setCurrentMail] = useState("");

  const confirm = useConfirm();

  const {
    id,
    tipo,
    fecha_venta,
    fecha_validez,
    fecha_salida,
    cliente_id,
    cuit,
    tipo_comprobante_id,
    forma_pago_id,
    notas,
    observaciones_factura,
    remito,
    numero_factura,
    pagos,
    items,
    cae,
    estado_id,
    orden,
    camioneta,
    aprobarFactura,
    emitirFactura,
    mi_pyme,
    devolverStock,
    enviarMail,
    clasificacion,
    bultos,
  } = venta;

  const dirRef = useRef("");
  const alertContext = useContext(AlertContext);

  const [productosCliente, setProductosCliente] = useState(null);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    console.log(fecha_salida);
  }, [fecha_salida]);

  useEffect(() => {
    if (current !== null || open) {
      dispatch(getProductos());
      dispatch(getClientes());
      dispatch(getCajas());
      dispatch(getCheques());
      dispatch(getChequesPropios());
      dispatch(getListasPrecios());
      dispatch(getTransportistas());
      dispatch(getZonas());
    }
    if (current !== null) {
      setVenta(current);
      setIsPreview(true);
    } else {
      if (open && open.id) {
        setVenta({
          ...emptyVenta(),
          cliente_id: open.id,
          cliente: open,
        });

        onChange({ value: open.id }, "cliente_id");
      } else setVenta(emptyVenta());
    }
  }, [current, open]);

  useEffect(() => {
    if (venta.cliente) setCurrentMail(venta.cliente.email_facturacion);
  }, [venta]);

  useEffect(() => {
    if (
      listas_precios &&
      listas_precios.length &&
      venta.cliente_id &&
      (!productosCliente || !productosCliente[venta.cliente_id])
    ) {
      const cliente = clientes.find((cli) => cli.id === cliente_id);
      const listaPrecioObj =
        listas_precios.find((lista) => lista.id === cliente.lista_precios_id) ||
        listas_precios[0];
      setProductosCliente({
        ...productosCliente,
        [venta.cliente_id]: listaPrecioObj.items.filter((p) => p.cod !== 999),
      });
    }
  }, [venta, listas_precios]);

  useEffect(() => {
    let itemsTotales =
      items && items.length
        ? items.reduce((a, b) => {
            if (isNaN(parseFloat(b.cantidad)) || isNaN(parseFloat(b.precio)))
              return a + 0;
            return a + b.cantidad;
          }, 0)
        : 0;

    let b = 0;
    let b_noiva = 0;
    let b3 = 0;
    let c = 0;
    let c3 = 0;

    let par = 0;
    for (let it of items) {
      if (!it.producto_id || !it.cantidad || !it.precio) continue;
      var bonificacion = isNaN(parseFloat(it.bonificacion))
        ? 0
        : parseFloat(it.bonificacion);
      var valor_item = BigNumber(
        parseFloat(it.precio) * (1 - bonificacion / 100)
      ).toNumber();
      var iva = isNaN(parseFloat(it.iva)) ? 0 : parseFloat(it.iva) / 100;
      if (clasificacion === 2) {
        if (it.producto_id !== idProductoOtros) {
          if (isPreview) {
            if (!it.iva) {
              b3 += it.cantidad * valor_item;
              c3 += it.cantidad;
            } else {
              b += it.cantidad * valor_item + it.cantidad * valor_item * iva;
              b_noiva += it.cantidad * valor_item;
              c += it.cantidad;
            }
          } else {
            if (it.cantidad % 2 === 0) {
              b +=
                (it.cantidad / 2) * valor_item +
                (it.cantidad / 2) * valor_item * iva;
              b_noiva += (it.cantidad / 2) * valor_item;
              c += it.cantidad / 2;

              b3 += (it.cantidad / 2) * valor_item;
              c3 += it.cantidad / 2;
            } else {
              //Separamos asi para evitar que queden todos acumulados en uno
              par++;
              if (par % 2 !== 0) {
                b +=
                  Math.ceil(it.cantidad / 2) * valor_item +
                  Math.ceil(it.cantidad / 2) * valor_item * iva;
                b_noiva += Math.ceil(it.cantidad / 2) * valor_item;
                c += Math.ceil(it.cantidad / 2);

                b3 += Math.floor(it.cantidad / 2) * valor_item;
                c3 += Math.floor(it.cantidad / 2);
              } else {
                b +=
                  Math.floor(it.cantidad / 2) * valor_item +
                  Math.floor(it.cantidad / 2) * valor_item * iva;
                b_noiva += Math.floor(it.cantidad / 2) * valor_item;
                c += Math.floor(it.cantidad / 2);
                b3 += Math.ceil(it.cantidad / 2) * valor_item;
                c3 += Math.ceil(it.cantidad / 2);
              }
            }
          }
        } else {
          b += it.cantidad * valor_item + it.cantidad * valor_item * iva;
          b_noiva += it.cantidad * valor_item;
          c += it.cantidad;
        }
      } else {
        b += it.cantidad * valor_item + it.cantidad * valor_item * iva;
        b_noiva += it.cantidad * valor_item;
        c += it.cantidad;
      }
    }
    let extra_rg_iva = {};
    if (
      venta &&
      venta.cliente_id &&
      clientes &&
      tieneRgIva(
        venta,
        clientes.find((cli) => cli.id === venta.cliente_id)
      )
    ) {
      setRgIva(BigNumber(b_noiva * 0.03).toNumber());
      extra_rg_iva = { rg_iva: BigNumber(b_noiva * 0.03).toNumber() };
    } else {
      setRgIva(0);
      extra_rg_iva = { rg_iva: 0 };
    }

    setBalance(BigNumber(b).toNumber());
    setCantidad(c);
    if (clasificacion === 2) {
      setBalanceC3(BigNumber(b3).toNumber());
    } else {
      setBalanceC3(BigNumber(b_noiva).toNumber());
    }
    setCantidadC3(c3);

    setVenta({
      ...venta,
      bultos: calcularBultos(venta),
      ...extra_rg_iva,
    });
  }, [items]);

  useEffect(() => {
    let itemsTmp = items;
    if (!discriminaIva(tipo_comprobante_id)) {
      itemsTmp = items.map((item) => ({ ...item, iva: 0 }));
    }
    setVenta({
      ...venta,
      ...(itemsTmp ? { items: itemsTmp } : {}),
    });
  }, [tipo_comprobante_id]);

  const onChange = (e, special_field = null) => {
    if (!special_field) {
      setVenta({ ...venta, [e.target.name]: e.target.value });
    } else if (special_field === "cliente_id") {
      const clienteTmp = clientes.find((p) => p.id === e.value);
      let fechaSalidaTmp = null;
      if (clienteTmp.dias_entrega && clienteTmp.dias_entrega.length) {
        fechaSalidaTmp = getNextDayOfTheWeek(
          clienteTmp.dias_entrega[0].dia
        ).toISOString();
      } else if (
        zonas.find((z) => z.id === clienteTmp.zona_id).dias_entrega &&
        zonas.find((z) => z.id === clienteTmp.zona_id).dias_entrega.length
      ) {
        fechaSalidaTmp = getNextDayOfTheWeek(
          zonas.find((z) => z.id === clienteTmp.zona_id).dias_entrega[0]
        ).toISOString();
      } else {
        fechaSalidaTmp = calcWorkingDays(new Date(), 2).toISOString();
      }

      setVenta({
        ...venta,
        tipo_comprobante_id: clienteTmp.tipo_comprobante_id,
        forma_pago_id: clienteTmp.ultima_forma_pago_id
          ? clienteTmp.ultima_forma_pago_id
          : clienteTmp.formas_pago[0].id,
        clasificacion: clienteTmp.clasificacion,
        ...(fechaSalidaTmp && { fecha_salida: fechaSalidaTmp }),
        cliente_id: e.value,
        cuit: clienteTmp.cuit,
      });
    } else if (special_field === "tipo") {
      setVenta({ ...venta, tipo: e.value });
    } else if (special_field === "tipo_comprobante_id") {
      let itemsTmp = null;
      if (!discriminaIva(e.target.value)) {
        itemsTmp = items.map((item) => ({ ...item, iva: 0 }));
      } else if (esNotaDeDebito(e.target.value)) {
        itemsTmp = [
          {
            producto_id: cIdProductoOtros,
            cantidad: 1,
            precio: 0,
            bonificacion: "",
            ...(discriminaIva(tipo_comprobante_id) ? { iva: 21 } : {}),
          },
        ];
      } else {
        itemsTmp = items.map((item) => ({
          ...item,
          ...(!item.iva ? { iva: 21 } : {}),
        }));
      }
      setVenta({
        ...venta,
        ...(itemsTmp ? { items: itemsTmp } : {}),
        tipo_comprobante_id: e.target.value,
      });
    } else if (special_field === "forma_pago_id") {
      setVenta({
        ...venta,
        forma_pago_id: e.target.value,
      });
    } else if (special_field === "clasificacion") {
      setVenta({
        ...venta,
        clasificacion: e.target.value,
        ...(e.target.value === 3
          ? {
              tipo_comprobante_id: 3,
              items: venta.items.map((i) => ({ ...i, iva: 0 })),
            }
          : {
              tipo_comprobante_id: clientes.find(
                (p) => p.id === venta.cliente_id
              ).tipo_comprobante_id,
              items: venta.items.map((i) => ({ ...i, iva: 21 })),
            }),
      });
    } else if (special_field === "estado_id") {
      setVenta({
        ...venta,
        estado_id: e.target.value,
      });
    } else if (special_field === "bultos") {
      setModificadorBultos(true);
      setVenta({
        ...venta,
        bultos: parseInt(e.target.value),
      });
    } else if (special_field === "camioneta") {
      setVenta({
        ...venta,
        camioneta: e.target.value,
      });
    } else if (special_field === "orden") {
      setVenta({
        ...venta,
        orden: e.target.value,
      });
    } else {
      setVenta({ ...venta, [special_field]: e });
    }
  };

  const tieneTransportista = (cliente_id, transportistas) => {
    if (
      clientes.find((cli) => cli.id === cliente_id) &&
      clientes.find((cli) => cli.id === cliente_id).transportista &&
      transportistas.find(
        (tra) =>
          tra.id ===
          clientes.find((cli) => cli.id === cliente_id).transportista_id
      ).destinos &&
      transportistas.find(
        (tra) =>
          tra.id ===
          clientes.find((cli) => cli.id === cliente_id).transportista_id
      ).destinos.length > 0 &&
      transportistas
        .find(
          (tra) =>
            tra.id ===
            clientes.find((cli) => cli.id === cliente_id).transportista_id
        )
        .destinos.find(
          (dest) =>
            dest.id === clientes.find((cli) => cli.id === cliente_id).destino_id
        )
    ) {
      return transportistas
        .find(
          (tra) =>
            tra.id ===
            clientes.find((cli) => cli.id === cliente_id).transportista_id
        )
        .destinos.find(
          (dest) =>
            dest.id === clientes.find((cli) => cli.id === cliente_id).destino_id
        );
    } else return false;
  };

  const calcularAbasto = (e) => {
    let pesoCalc = 0;
    items.forEach((it) => {
      if (it.producto_id !== idProductoOtros) {
        pesoCalc +=
          (productos.find((i) => i.id === it.producto_id).peso * it.cantidad) /
          1000;
      }
    });

    let precioCalc =
      transportistas
        .find(
          (tra) =>
            tra.id ===
            clientes.find((cli) => cli.id === cliente_id).transportista_id
        )
        .destinos.find(
          (dest) =>
            dest.id === clientes.find((cli) => cli.id === cliente_id).destino_id
        ).abasto * pesoCalc;
    let tmpItems = [];
    if (
      items.find(
        (it) =>
          it.producto_id === idProductoOtros && it.descripcion === "Abasto"
      )
    ) {
      tmpItems = items.map((it) =>
        it.producto_id === idProductoOtros && it.descripcion === "Abasto"
          ? { ...it, precio: precioCalc }
          : it
      );
    } else {
      tmpItems = items.concat([
        {
          producto_id: idProductoOtros,
          descripcion: "Abasto",
          precio: precioCalc,
          cantidad: 1,
        },
      ]);
    }
    setVenta({ ...venta, items: tmpItems });
  };

  const onChangeItem = (e, index, campo, floatVal = false) => {
    if (campo === "remove") {
      setVenta({
        ...venta,
        items: [...items.slice(0, index), ...items.slice(index + 1)],
      });
      return;
    }
    let val;
    if (!floatVal) {
      val = e.target.value;
    } else {
      val = e.floatValue;
    }
    let precioTmp = "";
    if (campo === "producto_id" && val !== idProductoOtros) {
      const cliente = clientes.find((cli) => cli.id === cliente_id);
      const listaPrecioObj =
        listas_precios.find((lista) => lista.id === cliente.lista_precios_id) ||
        listas_precios[0];
      precioTmp = listaPrecioObj.items.find(
        (item) => item.producto_id === val
      ).precio;
      precioTmp =
        Math.round((precioTmp / (1 - cliente.descuento / 100)) * 100) / 100;
    }
    setVenta({
      ...venta,
      items: [
        ...items.slice(0, index),
        {
          ...items[index],
          [campo]: val,
          ...(precioTmp && { precio: precioTmp }),
        },
        ...items.slice(index + 1),
      ],
    });
  };

  const agregarItem = (index) => {
    const currentCategoriaImpositiva = clientes.find(
      (cliente) => cliente.id === venta.cliente_id
    ).categoria_impositiva_id;
    const isExcentoIVA = currentCategoriaImpositiva === 4;
    if (items.length)
      setVenta({
        ...venta,
        items: [
          ...items,
          {
            producto_id: null,
            cantidad: "",
            precio: "",
            bonificacion: "",
            ...(discriminaIva(tipo_comprobante_id)
              ? { iva: !isExcentoIVA ? 21 : 0 }
              : {}),
          },
        ],
      });
    else {
      let itemsTmp = [];
      if (!cliente_id)
        alertContext.setAlert("Seleccione un cliente primero", "warning");
      else if (esNotaDeDebito(tipo_comprobante_id)) {
        itemsTmp = [
          {
            producto_id: cIdProductoOtros,
            cantidad: 1,
            precio: 0,
            bonificacion: "",
            ...(discriminaIva(tipo_comprobante_id)
              ? { iva: !isExcentoIVA ? 21 : 0 }
              : {}),
          },
        ];
        setVenta({
          ...venta,
          items: itemsTmp,
        });
      } else {
        const cliente = clientes.find((cli) => cli.id === cliente_id);
        const listaPrecioObj =
          listas_precios.find(
            (lista) => lista.id === cliente.lista_precios_id
          ) || listas_precios[0];
        productos.forEach((producto) => {
          if (
            !producto.nombre.includes("Granel") &&
            !producto.nombre.includes("Otros") &&
            !producto.nombre.includes("packs bonificados") &&
            producto.id !== 999
          ) {
            if (
              listaPrecioObj.items.find(
                (item) => item.producto_id === producto.id
              )
            ) {
              var precioTmp = listaPrecioObj.items.find(
                (item) => item.producto_id === producto.id
              ).precio;
              precioTmp =
                Math.round((precioTmp / (1 - cliente.descuento / 100)) * 100) /
                100;
              itemsTmp = [
                ...itemsTmp,
                {
                  producto_id: producto.id,
                  cantidad: "",
                  precio: precioTmp,
                  bonificacion: cliente.bonificacion || "",
                  ...(discriminaIva(tipo_comprobante_id)
                    ? { iva: !isExcentoIVA ? 21 : 0 }
                    : {}),
                },
              ];
            }
          }
        });
        setVenta({
          ...venta,
          items: itemsTmp,
        });
      }
    }
  };
  const traerItemsPedidoAnterior = async () => {
    if (clasificacion === 2) setIsPreview(true);
    if (items.length)
      alertContext.setAlert("Ya hay items en la venta", "warning");
    else {
      if (!cliente_id)
        alertContext.setAlert("Seleccione un cliente primero", "warning");
      else {
        const res = await axios.get(
          "/api/ventas/ultima_venta_cliente/" + cliente_id
        );

        if (!res.data)
          alertContext.setAlert("No hay ventas anteriores", "warning");
        else {
          const ventaAnterior = res.data;
          if (ventaAnterior && ventaAnterior.items)
            setVenta({
              ...venta,
              items: ventaAnterior.items
                .map((item) => ({
                  ...item,
                }))
                .reverse(),
            });
        }
      }
    }
  };

  const onChangePago = (e, index, campo) => {
    if (campo === "valor") {
      setVenta({
        ...venta,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], valor: e.floatValue },
          ...pagos.slice(index + 1),
        ],
      });
    } else if (campo === "fecha_pago") {
      setVenta({
        ...venta,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], fecha_pago: e },
          ...pagos.slice(index + 1),
        ],
      });
    } else if (campo === "cheque") {
      let chequeTmp = cheques.find((cheque) => cheque.id === e.value);
      setVenta({
        ...venta,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], valor: chequeTmp.valor, cheque: chequeTmp.id },
          ...pagos.slice(index + 1),
        ],
      });
    } else if (campo === "cheque_propio") {
      let chequeTmp = cheques_propios.find(
        (cheque_propio) => cheque_propio.id === e.value
      );
      setVenta({
        ...venta,
        pagos: [
          ...pagos.slice(0, index),
          {
            ...pagos[index],
            valor: chequeTmp.valor,
            cheque_propio: chequeTmp.id,
          },
          ...pagos.slice(index + 1),
        ],
      });
    } else {
      setVenta({
        ...venta,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], [campo]: e.target.value },
          ...pagos.slice(index + 1),
        ],
      });
    }
  };

  const onChangeCheck = (event, name) => {
    if (name === "emitirFactura") {
      if (event.target.checked) {
        if (estado_id === 1) {
          setVenta({ ...venta, aprobarFactura: true });
        }
        setVenta({ ...venta, emitirFactura: true });
      } else {
        if (estado_id === 1) {
          setVenta({ ...venta, aprobarFactura: false });
        }
        setVenta({ ...venta, emitirFactura: false });
      }
    } else if (name === "aprobarFactura") {
      if (event.target.checked) {
        setVenta({ ...venta, aprobarFactura: true });
      } else {
        setVenta({ ...venta, aprobarFactura: false });
        //setVenta({ ...venta, emitirFactura: false });
      }
    } else if (name === "devolverStock") {
      if (event.target.checked) {
        setVenta({ ...venta, devolverStock: 1 });
      } else {
        setVenta({ ...venta, devolverStock: 0 });
        //setVenta({ ...venta, emitirFactura: false });
      }
    } else if (name === "enviarMail") {
      if (event.target.checked) {
        setVenta({ ...venta, enviarMail: 1 });
      } else {
        setVenta({ ...venta, enviarMail: 0 });
        //setVenta({ ...venta, emitirFactura: false });
      }
    } else if (name === "mi_pyme") {
      if (event.target.checked) {
        setVenta({ ...venta, mi_pyme: 1 });
      } else {
        setVenta({ ...venta, mi_pyme: 0 });
        //setVenta({ ...venta, emitirFactura: false });
      }
    }
  };

  const agregarPago = () => {
    setVenta({
      ...venta,
      pagos: [...pagos, { valor: "", caja: "", fecha_pago: new Date() }],
    });
  };

  const agregarNotaDeCredito = () => {};

  const agregarNotaDeDebito = () => {};

  const onValueChange = (values, field_name) => {
    setVenta({ ...venta, [field_name]: values.floatValue });
  };

  const createOptionCliente = (label) => ({
    razon_social: label,
    nombre: label,
    _id: ObjectID(new Date().getTime()).toHexString(),
  });

  const handleCreateCliente = async (inputValue) => {
    const newOption = createOptionCliente(inputValue);

    clientes.push(newOption);
    dispatch(addCliente(newOption));

    setVenta({ ...venta, cliente: newOption.id });
  };

  const isDisabled = (fieldset = null) => {
    let disabled = cae || estado_id === 3;
    if (fieldset === "items" && estado_id === 2) {
      disabled = true;
    }
    if (fieldset === "forma_pago_id" && panel === "ventas") disabled = true;
    return disabled;
  };

  const onClickGuardar = (confirm, dispatch, venta, clientes, e) => {
    if (
      !cliente_id ||
      !cuit ||
      !tipo_comprobante_id ||
      !forma_pago_id ||
      !fecha_venta ||
      !fecha_validez
    ) {
      alertContext.setAlert("Completar campos obligatorios", "error");
    } else if (!items || !items.length) {
      alertContext.setAlert("Agregar items", "error");
    } else {
      setIsPreview(false);
      if (venta.emitirFactura) {
        const cli = clientes.find((p) => p.id === venta.cliente_id);

        confirm({
          description:
            `Desea facturar esta venta de "${
              cli ? cli.razon_social : "BORRADO"
            }"?\n` +
            (cli
              ? cli.email_facturacion
                ? "La factura se va a enviar a: " + cli.email_facturacion
                : "El e-mail de facturación no fue configurado."
              : ""),
          title: "ATENCION",
          cancellationText: "Cancelar",
          confirmationText: "Confirmar",
        }).then(() => onSubmit(e));
      } else {
        onSubmit(e);
      }
    }
  };
  const onClickEmitir = (confirm, dispatch, venta, clientes, e) => {
    if (
      !cliente_id ||
      !cuit ||
      !tipo_comprobante_id ||
      !forma_pago_id ||
      !fecha_venta ||
      !fecha_validez ||
      (tipo_comprobante_id === 8 && !numero_factura)
    ) {
      alertContext.setAlert("Completar campos obligatorios", "error");
    } else if (!items || !items.length) {
      alertContext.setAlert("Agregar items", "error");
    } else {
      const cli = clientes.find((p) => p.id === venta.cliente_id);
      confirm({
        description: `Desea emitir esta nota de ${
          esNotaDeDebito(tipo_comprobante_id) ? "débito" : "credito"
        } para "${cli ? cli.razon_social : "BORRADO"}"?\n`,
        title: "ATENCION",
        cancellationText: "Cancelar",
        confirmationText: "Confirmar",
      }).then(() => onSubmit(e, { ...venta, noEnviarMail: !enviarMail }));
    }
  };

  const onClickPreview = (confirm, dispatch, venta, clientes, e) => {
    setIsPreview(true);
    let itemsTmp = [];
    let b = 0;
    let b3 = 0;
    let par = 0;
    let tmpRes = [];
    for (let it of items) {
      if (!it.producto_id || !it.cantidad || !it.precio) continue;

      var bonificacion = isNaN(parseFloat(it.bonificacion))
        ? 0
        : parseFloat(it.bonificacion);
      var valor_item = parseFloat(it.precio) * (1 - bonificacion / 100);
      var iva = isNaN(parseFloat(it.iva)) ? 0 : parseFloat(it.iva) / 100;
      if (it.producto_id !== idProductoOtros) {
        if (it.cantidad % 2 === 0) {
          tmpRes.push({
            ...it,
            cantidad: it.cantidad / 2,
            subtotal:
              (it.cantidad / 2) * valor_item +
              (it.cantidad / 2) * valor_item * iva,
          });
          tmpRes.push({
            ...it,
            cantidad: it.cantidad / 2,
            iva: 0,
            subtotal: (it.cantidad / 2) * valor_item,
            clasificacion: 3,
          });
        } else {
          //Separamos asi para evitar que queden todos acumulados en uno
          par++;
          if (par % 2 !== 0) {
            tmpRes.push({
              ...it,
              cantidad: Math.ceil(it.cantidad / 2),
              subtotal:
                Math.ceil(it.cantidad / 2) * valor_item +
                Math.ceil(it.cantidad / 2) * valor_item * iva,
            });
            tmpRes.push({
              ...it,
              cantidad: Math.floor(it.cantidad / 2),
              iva: 0,
              subtotal: Math.floor(it.cantidad / 2) * valor_item,
              clasificacion: 3,
            });
          } else {
            tmpRes.push({
              ...it,
              cantidad: Math.floor(it.cantidad / 2),
              subtotal:
                Math.floor(it.cantidad / 2) * valor_item +
                Math.floor(it.cantidad / 2) * valor_item * iva,
            });
            tmpRes.push({
              ...it,
              cantidad: Math.ceil(it.cantidad / 2),
              iva: 0,
              subtotal: Math.ceil(it.cantidad / 2) * valor_item,
              clasificacion: 3,
            });
          }
        }
      } else {
        tmpRes.push({
          ...it,
          subtotal: it.cantidad * valor_item + it.cantidad * valor_item * iva,
          clasificacion: 1,
        });
      }
    }

    itemsTmp = tmpRes.slice();
    setVenta({ ...venta, items: itemsTmp });
  };

  const onSubmit = (e, ventaCustom = null) => {
    e.preventDefault();

    try {
      if (!current || venta._NUEVA) {
        dispatch(addVenta(ventaCustom ? ventaCustom : venta)).then((r) => {
          alertContext.setAlert("Venta guardada!", "success");
        });
      } else {
        dispatch(updateVenta(ventaCustom ? ventaCustom : venta)).then((r) =>
          alertContext.setAlert("Venta guardada!", "success")
        );
      }
    } catch (error) {}

    clearAll();
    handleClose();
  };

  const clearAll = () => {
    setVenta(emptyVenta());
    dispatch(clearCurrentVenta());
  };

  const classes = useStyles();

  function handleClose() {
    setIsPreview(false);
    dispatch(clearCurrentVenta());
    dispatch(setOpenVenta(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={true}
      style={
        !useMediaQuery(useTheme().breakpoints.down("sm"))
          ? { width: "90%", height: "95%", margin: "auto" }
          : {}
      }
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle
        id="max-width-dialog-title"
        color="primary"
        className={classes.formHeader}
      >
        {!current ? "Nueva Venta" : "Editar Venta"}
      </DialogTitle>

      <DialogContent dividers={true}>
        <form onSubmit={(e) => false}>
          <Grid container className={classes.root} spacing={2}>
            {!loading_clientes && clientes && (
              <Fragment>
                <Grid item xs={12} md={3}>
                  {cae ? (
                    <TextField
                      margin="dense"
                      name="remito"
                      label="Cliente"
                      variant="outlined"
                      multiline
                      disabled={true}
                      id="cliente_id"
                      value={clientes.find((p) => p.id === cliente_id).nombre}
                      fullWidth
                    />
                  ) : (
                    <FormControl
                      variant="outlined"
                      disabled={isDisabled()}
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "8px",
                      }}
                    >
                      <ReactSelectNonCreate
                        fullWidth
                        value={
                          cliente_id &&
                          clientes.find((p) => p.id === cliente_id)
                            ? {
                                label: clientes.find((p) => p.id === cliente_id)
                                  .nombre,
                                value: cliente_id,
                              }
                            : ""
                        }
                        name="cliente_id"
                        placeholder="Cliente *"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            marginTop: "-8px",
                          }),
                        }}
                        isDisabled={isDisabled()}
                        menuPortalTarget={document.body}
                        onChange={(e) => onChange(e, "cliente_id")}
                        options={clientes
                          .filter((c) => !c.desactivado)
                          .map((cliente) => ({
                            value: cliente.id,
                            label: cliente.nombre,
                          }))}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <NumberFormat
                    customInput={TextField}
                    format="##-########-#"
                    mask="_"
                    decimalScale={0}
                    allowNegative={false}
                    type="text"
                    margin="dense"
                    disabled={isDisabled()}
                    variant="outlined"
                    fullWidth
                    style={{ paddingBottom: "10px" }}
                    value={cuit || ""}
                    name="cuit"
                    label="CUIT *"
                    onValueChange={(values) => onValueChange(values, "cuit")}
                  />
                </Grid>
              </Fragment>
            )}
            {panel !== "administrativo" ? (
              <Grid item xs={12} md={3}>
                <FormControl
                  variant="outlined"
                  style={{
                    height: "40px",
                    width: "100%",
                    paddingTop: "8px",
                  }}
                >
                  <InputLabel htmlFor="select-forma-pago">
                    Clasificacion *
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        id="select-forma-pago"
                      />
                    }
                    disabled={isDisabled()}
                    fullWidth
                    value={clasificacion ? clasificacion : ""}
                    name="clasificacion"
                    onChange={(e) => onChange(e, "clasificacion")}
                  >
                    {(open && open.tipo_comprobante_id === 3
                      ? [3]
                      : [1, 2, 3]
                    ).map((tipo, index) => (
                      <MenuItem value={tipo} key={index}>
                        {tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} md={3}>
                <FormControl
                  variant="outlined"
                  style={{
                    height: "40px",
                    width: "100%",
                    paddingTop: "8px",
                  }}
                >
                  <InputLabel htmlFor="select-forma-pago">
                    Tipo de Comprobante *
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        id="select-forma-pago"
                      />
                    }
                    disabled={isDisabled("tipo_comprobante_id")}
                    fullWidth
                    value={tipo_comprobante_id ? tipo_comprobante_id : ""}
                    name="tipo_comprobante_id"
                    onChange={(e) => onChange(e, "tipo_comprobante_id")}
                  >
                    {tiposComprobante.map((tipo) => (
                      <MenuItem value={tipo.id} key={tipo.id}>
                        {tipo.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <FormControl
                variant="outlined"
                style={{
                  height: "40px",
                  width: "100%",
                  paddingTop: "8px",
                }}
              >
                <InputLabel htmlFor="select-forma-pago">
                  Forma de Pago *
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                      id="select-forma-pago"
                    />
                  }
                  disabled={isDisabled("forma_pago_id")}
                  fullWidth
                  value={forma_pago_id || ""}
                  name="forma_pago_id"
                  onChange={(e) => onChange(e, "forma_pago_id")}
                >
                  {tiposFormasDePago.map((tipo, index) => (
                    <MenuItem value={tipo.id} key={tipo.id}>
                      {tipo.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: "10px" }}>
              Fecha de Venta: *{" "}
              <DatePicker
                onChange={(date) => onChange(date, "fecha_venta")}
                disabled={isDisabled()}
                value={fecha_venta ? moment(fecha_venta).toDate() : null}
                format="dd/MM/y"
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: "10px" }}>
              Fecha Validez: *{" "}
              <DatePicker
                onChange={(date) => onChange(date, "fecha_validez")}
                disabled={isDisabled()}
                value={fecha_validez ? moment(fecha_validez).toDate() : null}
                format="dd/MM/y"
              />
            </Grid>
            {!tipo_comprobante_id ||
            [4, 5, 6, 8, 11, 12, 13].indexOf(tipo_comprobante_id) === -1 ? (
              <Grid item xs={12} md={4} style={{ marginTop: "10px" }}>
                Salida Est.:{" "}
                <DatePicker
                  onChange={(date) => onChange(date, "fecha_salida")}
                  disabled={
                    authContext.usuario.vendedor_id &&
                    estado_id &&
                    estado_id !== cEstados.Pendiente &&
                    true
                  }
                  value={fecha_salida ? moment(fecha_salida).toDate() : null}
                  format="dd/MM/y"
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={4}></Grid>
            )}
            {panel === "logistico" && (
              <Fragment>
                <Grid item xs={12} md={1}>
                  <FormControl
                    variant="outlined"
                    style={{
                      height: "40px",
                      width: "100%",
                      paddingTop: "8px",
                    }}
                  >
                    <InputLabel htmlFor="select-forma-pago">
                      Camioneta
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          id="select-forma-pago"
                        />
                      }
                      disabled={isDisabled()}
                      fullWidth
                      value={camioneta || ""}
                      name="camioneta"
                      onChange={(e) => onChange(e, "camioneta")}
                    >
                      {[1, 2, 3, 4].map((tipo, index) => (
                        <MenuItem value={tipo} key={index}>
                          {tipo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Fragment>
            )}
            <Grid item xs={12} md={panel === "logistico" ? 11 : 12}>
              <TextField
                margin="dense"
                name="notas"
                label="Notas Internas"
                variant="outlined"
                multiline
                id="notas"
                disabled={
                  authContext.usuario.vendedor_id &&
                  estado_id &&
                  estado_id !== cEstados.Pendiente &&
                  true
                }
                value={notas || ""}
                onChange={onChange}
                fullWidth
                rowsMax="3"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={
                clasificacion !== 3 && balance + balanceC3 > cValorMiPyme
                  ? 8
                  : 12
              }
            >
              <TextField
                margin="dense"
                name="observaciones_factura"
                label="Observaciones para la factura"
                variant="outlined"
                multiline
                id="observaciones_factura"
                disabled={isDisabled()}
                value={observaciones_factura || ""}
                onChange={onChange}
                fullWidth
                rowsMax="3"
              />
            </Grid>
            {clasificacion !== 3 &&
              balance + balanceC3 > cValorMiPyme &&
              ((open && open.mi_pyme === 1) ||
                (open && open.grupo && open.grupo.mi_pyme === 1)) && (
                <FormControlLabel
                  value="mi_pyme"
                  control={
                    <Checkbox
                      margin="dense"
                      name="mi_pyme"
                      label="Mi PYME?"
                      value="1"
                      checked={mi_pyme === 1}
                      onChange={(e) => onChangeCheck(e, "mi_pyme")}
                      className={classes.textField}
                      variant="outlined"
                    />
                  }
                  label="Mi PYME?"
                  labelPlacement="end"
                />
              )}

            {panel === "administrativo" &&
              (!tipo_comprobante_id ||
                [4, 5, 6, 8, 11, 12, 13].indexOf(tipo_comprobante_id) ===
                  -1) && (
                <Grid item xs={12} md={3}>
                  <TextField
                    margin="dense"
                    name="remito"
                    label="# Remito"
                    variant="outlined"
                    multiline
                    id="remito"
                    disabled={isDisabled()}
                    value={remito || ""}
                    onChange={onChange}
                    fullWidth
                  />
                </Grid>
              )}
            {panel === "administrativo" &&
              tipo_comprobante_id &&
              tipo_comprobante_id === 8 && (
                <Grid item xs={12} md={3}>
                  <TextField
                    margin="dense"
                    name="numero_factura"
                    label="Num. Nota de Crédito E *"
                    variant="outlined"
                    multiline
                    id="numero_factura"
                    disabled={isDisabled()}
                    value={numero_factura || ""}
                    onChange={onChange}
                    fullWidth
                  />
                </Grid>
              )}
          </Grid>
          <h2 color="primary">Items: </h2>

          <Grid container className={classes.root} spacing={1}>
            {items &&
              !loading_productos &&
              productosCliente &&
              productosCliente[venta.cliente_id] &&
              items.map((item, index) => {
                return (
                  <Fragment key={`items[${index}]`}>
                    <Grid
                      item
                      md={item.producto_id === idProductoOtros ? 1 : 2}
                    >
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-producto"
                            inputProps={{ tabIndex: "-1" }}
                          />
                        }
                        fullWidth
                        tabIndex={-1}
                        disabled={isDisabled("items")}
                        value={item.producto_id ? item.producto_id : ""}
                        name={`items[${index}].producto_id`}
                        onChange={(e) => onChangeItem(e, index, "producto_id")}
                      >
                        {productosCliente[venta.cliente_id]
                          .concat(productos.find((prod) => prod.cod === 999))
                          .map((producto) => (
                            <MenuItem
                              value={
                                producto.producto
                                  ? producto.producto.id
                                  : producto.id
                              }
                              key={
                                producto.producto
                                  ? producto.producto.id
                                  : producto.id
                              }
                            >
                              {producto.producto
                                ? producto.producto.nombre
                                : producto.nombre}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    {item.producto_id === 6 && (
                      <Grid item md={1}>
                        <TextField
                          tabIndex={-1}
                          margin="dense"
                          name={`items[${index}].descripcion`}
                          label="Descripcion"
                          variant="outlined"
                          multiline
                          disabled={isDisabled("items")}
                          id={`items[${index}].descripcion`}
                          value={item.descripcion || ""}
                          onChange={(e) =>
                            onChangeItem(e, index, "descripcion")
                          }
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item md={1}>
                      <NumberFormat
                        customInput={TextField}
                        decimalSeparator=","
                        thousandSeparator="."
                        mask=""
                        prefix=""
                        tabIndex={index * 100}
                        decimalScale={0}
                        allowNegative={false}
                        margin="dense"
                        name={`items[${index}].cantidad`}
                        label="Cantidad"
                        disabled={isDisabled("items")}
                        value={item.cantidad}
                        onValueChange={(e) =>
                          onChangeItem(e, index, "cantidad", true)
                        }
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={discriminaIva(tipo_comprobante_id) ? 1 : 1}>
                      <NumberFormat
                        customInput={CustomTextField}
                        decimalSeparator=","
                        thousandSeparator="."
                        mask=""
                        tabIndex={-1}
                        prefix="$"
                        decimalScale={2}
                        allowNegative={false}
                        margin="dense"
                        name={`items[${index}].precio`}
                        label="Valor un."
                        value={item.precio}
                        disabled={isDisabled("items")}
                        onValueChange={(e) =>
                          onChangeItem(e, index, "precio", true)
                        }
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={1}>
                      <NumberFormat
                        customInput={CustomTextField}
                        decimalSeparator=","
                        thousandSeparator="."
                        mask=""
                        suffix="%"
                        tabIndex={-1}
                        disabled={isDisabled("items")}
                        allowNegative={false}
                        margin="dense"
                        name={`items[${index}].bonificacion`}
                        label="Bonif. %"
                        value={item.bonificacion}
                        onValueChange={(e) =>
                          onChangeItem(e, index, "bonificacion", true)
                        }
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={1}>
                      {discriminaIva(tipo_comprobante_id) && (
                        <NumberFormat
                          customInput={CustomTextField}
                          decimalSeparator=","
                          mask=""
                          suffix="%"
                          decimalScale={2}
                          tabIndex={-1}
                          style={{ width: "50%" }}
                          disabled={isDisabled("items")}
                          allowNegative={false}
                          margin="dense"
                          name={`items[${index}].iva`}
                          label="IVA %"
                          value={item.iva}
                          onValueChange={(e) =>
                            onChangeItem(e, index, "iva", true)
                          }
                          className={classes.textField}
                          variant="outlined"
                        />
                      )}
                      {!isDisabled("items") && (
                        <Button
                          onClick={(e) =>
                            onChangeItem(e, index, "remove", true)
                          }
                          variant="contained"
                          color="secondary"
                          tabIndex={-1}
                          style={{
                            width: "28px",
                            top: "12px",
                            minWidth: "28px",
                            marginLeft: "10px",
                          }}
                        >
                          <Icon className={classes.listIcon}>cancel</Icon>
                        </Button>
                      )}
                    </Grid>

                    {index % 2 === 0 && <span className="divider"></span>}
                  </Fragment>
                );
              })}
          </Grid>
          {!loading_productos && !isDisabled("items") && (
            <Fragment>
              <h4>
                <Button
                  color="primary"
                  href="#!"
                  onClick={traerItemsPedidoAnterior}
                >
                  Traer Items pedido anterior
                </Button>
                <Button color="primary" href="#!" onClick={agregarItem}>
                  Agregar Item
                </Button>
                <br></br>
                {!esNotaDeDebito(tipo_comprobante_id) &&
                venta.items.length > 0 ? (
                  <>
                    <TextField
                      id="standard-textarea"
                      label={`Cantidad de Bultos: ${bultos || 0}`}
                      placeholder="Elija Cantidad"
                      multiline
                      onChange={(e) => onChange(e, "bultos")}
                    />
                  </>
                ) : (
                  <></>
                )}
              </h4>

              {!loading_clientes &&
                !loading_transportistas &&
                transportistas &&
                clientes &&
                tieneTransportista(cliente_id, transportistas) && (
                  <span className={classes.abasto}>
                    Recordatorio Abasto:{" "}
                    {
                      transportistas
                        .find(
                          (tra) =>
                            tra.id ===
                            clientes.find((cli) => cli.id === cliente_id)
                              .transportista_id
                        )
                        .destinos.find(
                          (dest) =>
                            dest.id ===
                            clientes.find((cli) => cli.id === cliente_id)
                              .destino_id
                        ).abasto
                    }{" "}
                    $/kg -{" "}
                    <a href="javascript:void(0)" onClick={calcularAbasto}>
                      Calcular abasto
                    </a>
                  </span>
                )}
            </Fragment>
          )}
          {panel !== "ventas" && false && (
            <Fragment>
              <h2 color="primary">PAGOS: </h2>
              {pagos &&
                pagos.map((pago, index) => {
                  return (
                    <Grid
                      key={`pagos[${index}]`}
                      container
                      className={classes.root}
                      spacing={2}
                    >
                      {!loading_cajas && !loading_cheques && (
                        <Grid item xs={12} md={4}>
                          <FormControl
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "100%",
                              paddingTop: "8px",
                            }}
                          >
                            <InputLabel htmlFor="select-tipo-venta">
                              Caja
                            </InputLabel>
                            <Select
                              input={
                                <OutlinedInput
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  value={pago.caja}
                                  id="select-tipo-venta"
                                />
                              }
                              fullWidth
                              name={`pagos[${index}].caja`}
                              onChange={(e) => onChangePago(e, index, "caja")}
                              value={pago.caja}
                            >
                              {cajas.map((caja) =>
                                tipo_comprobante_id === 3
                                  ? caja.nombre === "Caja X" && (
                                      <MenuItem value={caja.id} key={caja.id}>
                                        {caja.nombre} / {caja.tipo}
                                      </MenuItem>
                                    )
                                  : caja.nombre !== "Caja X" &&
                                    caja.nombre !== "Cheques propios" && (
                                      <MenuItem value={caja.id} key={caja.id}>
                                        {caja.nombre} / {caja.tipo}
                                      </MenuItem>
                                    )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {!loading_cheques &&
                      cajas.find(
                        (caja) =>
                          caja.id === pago.caja_id &&
                          caja.tipo === "Cheque terceros"
                      ) ? (
                        <Grid item xs={12} md={4}>
                          <FormControl
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "100%",
                              paddingTop: "8px",
                            }}
                          >
                            <ReactSelectNonCreate
                              value={
                                pago.cheque_id &&
                                cheques.find((p) => p.id === pago.cheque_id)
                                  ? {
                                      label:
                                        cheques.find(
                                          (p) => p.id === pago.cheque_id
                                        ).fecha_formatted +
                                        " / " +
                                        cheques.find(
                                          (p) => p.id === pago.cheque_id
                                        ).razon_social +
                                        " / $" +
                                        cheques.find(
                                          (p) => p.id === pago.cheque_id
                                        ).valor,
                                      value: pago.cheque_id,
                                    }
                                  : ""
                              }
                              fullWidth
                              name={`pagos[${index}].cheque`}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                  marginTop: "-8px",
                                }),
                              }}
                              menuPortalTarget={document.body}
                              onChange={(e) => onChangePago(e, index, "cheque")}
                              options={cheques
                                .filter(
                                  (cheque) =>
                                    cheque.lanzamientoCajaObj === undefined ||
                                    cheque.lanzamientoCajaObj.cheque ===
                                      pago.cheque
                                )
                                .map((cheque) => ({
                                  value: cheque.id,
                                  label:
                                    cheque.fecha_formatted +
                                    " / " +
                                    cheque.razon_social +
                                    " / $" +
                                    cheque.valor,
                                }))}
                            />
                          </FormControl>
                          <input
                            type="hidden"
                            name={`pagos[${index}].valor`}
                            onChange={(e) => onChangePago(e, index, "valor")}
                            value={
                              pago.valor
                                ? pago.valor.$numberDecimal
                                  ? parseFloat(pago.valor.$numberDecimal)
                                  : pago.valor
                                : ""
                            }
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={3}>
                          <NumberFormat
                            customInput={TextField}
                            decimalSeparator=","
                            thousandSeparator="."
                            mask=""
                            prefix="$"
                            decimalScale={2}
                            value={
                              pago.valor
                                ? pago.valor.$numberDecimal
                                  ? parseFloat(pago.valor.$numberDecimal)
                                  : pago.valor
                                : ""
                            }
                            type="text"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Valor"
                            style={{ paddingBottom: "10px" }}
                            name={`pagos[${index}].valor`}
                            onValueChange={(e) =>
                              onChangePago(e, index, "valor")
                            }
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={4} style={{ marginTop: "10px" }}>
                        Fecha Pago:{" "}
                        <DatePicker
                          onChange={(date) =>
                            onChangePago(date, index, "fecha_pago")
                          }
                          value={
                            pago.fecha_pago
                              ? moment(pago.fecha_pago).toDate()
                              : null
                          }
                          format="dd/MM/y"
                        />
                      </Grid>
                    </Grid>
                  );
                })}

              <h4>
                <Button color="primary" href="#!" onClick={agregarPago}>
                  Agregar Pago
                </Button>
              </h4>
            </Fragment>
          )}
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}></Grid>{" "}
          </Grid>
        </form>
      </DialogContent>
      {!saving ? (
        <DialogActions className={classes.formFooter}>
          <h2 style={{ textAlign: "right" }}>
            {clasificacion !== 2 && (
              <Fragment>
                Total: {cantidad} | $
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  value={
                    Math.round(balance * 100) / 100 +
                    Math.round(rgIva * 100) / 100
                    //
                  }
                />
              </Fragment>
            )}
            {clasificacion === 1 && (
              <span style={{ fontSize: "16px" }}>
                {" "}
                (s/IVA: $
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  value={
                    Math.round(balanceC3 * 100) / 100
                    //
                  }
                />
                )
              </span>
            )}

            {clasificacion === 2 && (
              <Fragment>
                <span style={{ fontSize: "14px" }}>
                  Subtotal 1: {cantidad} / $
                  <NumberFormat
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    value={
                      Math.round(balance * 100) / 100 +
                      Math.round(rgIva * 100) / 100
                      //
                    }
                  />{" "}
                  - Subtotal 3: {cantidadC3} / $
                  <NumberFormat
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    value={
                      Math.round(balanceC3 * 100) / 100
                      //
                    }
                  />
                </span>{" "}
                - Total: {cantidad + cantidadC3} / $
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  value={
                    Math.round((balance + balanceC3) * 100) / 100 +
                    Math.round(rgIva * 100) / 100
                    //
                  }
                />{" "}
              </Fragment>
            )}
            {rgIva > 0 && (
              <span style={{ fontSize: "16px" }}>
                {" "}
                (RG IVA: $
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  value={
                    Math.round(rgIva * 100) / 100
                    //
                  }
                />
                )
              </span>
            )}
          </h2>
          {cae || (estado_id === 3 && !discriminaIva(tipo_comprobante_id)) ? (
            <Fragment>
              <Button
                onClick={(e) =>
                  onClickGuardar(confirm, dispatch, venta, clientes, e)
                }
                variant="contained"
                color="primary"
                type="submit"
              >
                Guardar
              </Button>
              <Button
                onClick={(e) => {
                  if (venta.tipo_comprobante_id !== 7) {
                    let w = window.open(
                      "/api/invoice/pdf/triplicado/" + venta.id
                    );
                  } else {
                    let w = window.open("/api/invoice/factura_e/" + venta.id);
                  }
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                <Icon className={classes.listIcon}>receipt</Icon> Factura
              </Button>
              <Button
                onClick={async (e) => {
                  setEnviarMailDisabled(true);
                }}
                disabled={enviarMailDisabled}
                variant="contained"
                color="primary"
                type="submit"
              >
                <Icon className={classes.listIcon}>mail</Icon> Enviar por Mail
              </Button>
              {enviarMailDisabled && (
                <>
                  <TextField
                    value={currentMail || ""}
                    onChange={(e) => setCurrentMail(e.target.value)}
                  />

                  <Button
                    onClick={async (e) => {
                      try {
                        const res = await axios.get(
                          "/api/ventas/reenviarFactura/" + venta.id
                        );
                        alertContext.setAlert(
                          "Factura enviada correctamente para " +
                            res.data.mails,
                          "success"
                        );
                      } catch (e) {
                        alertContext.setAlert(
                          "Error enviando mail " +
                            e?.response?.data?.error?.msg,
                          "error"
                        );
                      }
                      setEnviarMailDisabled(false);
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <Icon className={classes.listIcon}>check</Icon>
                  </Button>

                  <Button
                    onClick={async (e) => {
                      setEnviarMailDisabled(false);
                    }}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    <Icon className={classes.listIcon}>cancel</Icon>
                  </Button>
                </>
              )}
              <Button
                onClick={(e) => {
                  let w = window.open("/api/remito/pdf/" + venta.id);
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                <Icon className={classes.listIcon}>local_shipping</Icon> Remito
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {" "}
              {clasificacion === 2 && !isPreview && panel === "ventas" ? (
                <Button
                  onClick={(e) =>
                    onClickPreview(confirm, dispatch, venta, clientes, e)
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Previsualizar
                </Button>
              ) : (
                <Fragment>
                  {!tipo_comprobante_id ||
                  [4, 5, 6, 8, 11, 12, 13].indexOf(tipo_comprobante_id) ===
                    -1 ? (
                    <>
                      {venta &&
                        venta.cliente &&
                        venta.cliente.plazo_pago < 30 && (
                          <>
                            {" "}
                            <Button
                              onClick={(e) => {
                                let w = window.open(
                                  "/api/invoice/pdfPresupuesto/" + venta.id
                                );
                              }}
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              <Icon className={classes.listIcon}>receipt</Icon>{" "}
                              Presupuesto
                            </Button>
                          </>
                        )}
                      <Button
                        onClick={(e) =>
                          onClickGuardar(confirm, dispatch, venta, clientes, e)
                        }
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </>
                  ) : (
                    <Fragment>
                      {!esNotaDeDebito(tipo_comprobante_id) && (
                        <FormControlLabel
                          value="devolverStock"
                          control={
                            <Checkbox
                              margin="dense"
                              name="devolverStock"
                              label="Devolver Stock?"
                              value="1"
                              checked={devolverStock === 1}
                              onChange={(e) =>
                                onChangeCheck(e, "devolverStock")
                              }
                              className={classes.textField}
                              variant="outlined"
                            />
                          }
                          label="Devolver Stock?"
                          labelPlacement="end"
                        />
                      )}
                      {tipo_comprobante_id !== 6 && (
                        <FormControlLabel
                          value="enviarMail"
                          control={
                            <Checkbox
                              margin="dense"
                              name="enviarMail"
                              label="Enviar Mail?"
                              value="1"
                              checked={enviarMail === 1}
                              onChange={(e) => onChangeCheck(e, "enviarMail")}
                              className={classes.textField}
                              variant="outlined"
                            />
                          }
                          label="Enviar Mail?"
                          labelPlacement="end"
                        />
                      )}
                      <Button
                        onClick={(e) => {
                          setVenta({ ...venta, emitirFactura: true });
                          onClickEmitir(
                            confirm,
                            dispatch,
                            { ...venta, emitirFactura: true },
                            clientes,
                            e
                          );
                        }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Emitir
                      </Button>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}

          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      ) : (
        <DialogActions className={classes.formFooter}>
          <CircularProgress color="secondary" />
        </DialogActions>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.ventas.current,
    open: state.ventas.open,
    error: state.ventas.error,
    saving: state.ventas.saving,
    all_ventas: state.ventas.ventas,
    zonas: state.zonas.zonas,
    bypassIo: state.ventas.bypassIo,
    clientes: state.clientes.clientes,
    loading_clientes: state.clientes.loading,
    cajas: state.cajas.cajas,
    loading_cajas: state.cajas.loading,
    cheques: state.cheques.cheques,
    loading_cheques: state.cheques.loading,
    cheques_propios: state.chequesPropios.cheques_propios,
    loading_cheques_propios: state.chequesPropios.loading,
    productos: state.productos.productos,
    loading_productos: state.productos.loading,
    listas_precios: state.listasPrecios.listas_precios,
    loading_transportistas: state.transportistas.loading,
    transportistas: state.transportistas.transportistas,
  };
};

export default connect(mapStateToProps)(VentaForm);
