import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import React, { useEffect } from "react";
import { getZonas } from "../../actions/zonas";
import { getVendedores } from "../../actions/vendedores";
import { connect } from "react-redux";
import { filterCajas } from "../../actions/cajas";
import { canales } from "../../config/constantes";

const CajaFilter = ({ zonas, vendedores, filter, filtered, dispatch }) => {
  useEffect(() => {
    dispatch(getZonas());
    dispatch(getVendedores());
  }, [dispatch]);

  const onChange = (e) => {
    dispatch(filterCajas({ ...filter, nombre: e.target.value }));
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Grid container spacing={2}>
        <Grid item xs={9} md={2}>
          <TextField
            type="text"
            label="Filtrar cajas"
            margin="dense"
            variant="outlined"
            onChange={onChange}
            fullWidth
            value={filter.nombre}
            style={{ paddingBottom: "10px" }}
          />
        </Grid>{" "}
        <Grid item xs={3} md={3}>
          <div style={{ paddingTop: "12px" }}>
            Rango de fechas:{" "}
            <DateRangePicker
              value={filter.fechaLanzamiento}
              onChange={(ranges) => {
                console.log(ranges);
                dispatch(
                  filterCajas({
                    ...filter,
                    fechaLanzamiento: ranges,
                  })
                );
              }}
              locale="es-AR"
            />{" "}
          </div>
        </Grid>{" "}
        <Grid item xs={3} md={3}>
          {zonas && zonas.length > 0 && (
            <FormControl
              variant="outlined"
              style={{
                height: "40px",
                width: "100%",
                paddingTop: "8px",
              }}
            >
              <InputLabel htmlFor="select-tipo-comprobante">Zona</InputLabel>
              <Select
                input={
                  <OutlinedInput
                    style={{
                      height: "40px",
                      width: "80%",
                    }}
                    id="select-tipo-comprobante"
                  />
                }
                multiple
                value={filter.zonas}
                name="zona"
                onChange={(e) => {
                  dispatch(
                    filterCajas({
                      ...filter,
                      zonas: e.target.value,
                    })
                  );
                }}
              >
                {zonas.map((zona) => (
                  <MenuItem value={zona.id} key={zona.id}>
                    {zona.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={3} md={2}>
          {canales && canales.length > 0 && (
            <FormControl
              variant="outlined"
              style={{
                height: "40px",
                width: "100%",
                paddingTop: "8px",
              }}
            >
              <InputLabel htmlFor="select-tipo-comprobante">Canal</InputLabel>
              <Select
                input={
                  <OutlinedInput
                    style={{
                      height: "40px",
                      width: "80%",
                    }}
                    id="select-tipo-comprobante"
                  />
                }
                multiple
                value={filter.canales}
                name="zona"
                onChange={(e) => {
                  dispatch(
                    filterCajas({
                      ...filter,
                      canales: e.target.value,
                    })
                  );
                }}
              >
                {canales.map((canal) => (
                  <MenuItem value={canal.id} key={canal.id}>
                    {canal.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={3} md={2}>
          {false && vendedores && vendedores.length > 0 && (
            <FormControl
              variant="outlined"
              style={{
                height: "40px",
                width: "100%",
                paddingTop: "8px",
              }}
            >
              <InputLabel htmlFor="select-tipo-comprobante">Vendedor</InputLabel>
              <Select
                input={
                  <OutlinedInput
                    style={{
                      height: "40px",
                      width: "80%",
                    }}
                    id="select-tipo-comprobante"
                  />
                }
                value={filter.vendedores}
                name="vendedor"
                onChange={(e) => {
                  console.log(filter.vendedores,e.target.value);
                  dispatch(
                    filterCajas({
                      ...filter,
                      vendedor: e.target.value,
                    })
                  );
                }}
              >
                {vendedores.map((vendedor) => (
                  <MenuItem value={vendedor.id} key={vendedor.id}>
                    {vendedor.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.cajas.filter,
    filtered: state.cajas.filtered,
    cajas: state.cajas,
    zonas: state.zonas.zonas,
    vendedores: state.vendedores.vendedores,
  };
};

export default connect(mapStateToProps)(CajaFilter);
